export const b2cHelpPageEvents = {
    "b2cSearchClickHelpCenter": {
        "event": "b2c_Search_click_help_center",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "Search_click_help_center"
    },
    "b2cSuggestedFAQclick": {
        "event": "b2c_Suggested_FAQ_click",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "Suggested_FAQ_click"
    },
    "b2cCrossHelpCenter": {
        "event": "b2c_cross_help_center",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "cross_help_center",
        "eventLabel": "NA"
    },
    "b2cViewAllFaq": {
        "event": "b2c_View_all_faq",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "View_all_faq",
        "eventLabel": "NA"
    },
    "b2cChatOnWhatsapp": {
        "event": "b2c_Chat_on_whatsapp",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "Chat_on_whatsapp",
        "eventLabel": "NA"
    },
    "b2cRequestAcall": {
        "event": "b2c_request_a_call",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "request_a_call",
        "eventLabel": "NA"
    },
    "b2cMailClick": {
        "event": "b2c_Mail_click",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "Mail_click",
        "eventLabel": "NA"
    },
    "b2cFAQcategoryClick": {
        "event": "b2c_FAQ_category_click",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "FAQ_category_click"
    },
    "b2cSearchHC": {
        "event": "b2c_search_HC",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "search_HC"
    },
    "b2cFaqHelpfulSurvey": {
        "event": "b2c_Faq_helpful_survey",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "Faq_helpful_survey"
    },
    "b2cSubCategoryQuestionClick": {
        "event": "b2c_Sub_category_question_click",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "Sub_category_question_click"
    },
    "b2cQuestionClickViaSearch": {
        "event": "b2c_question_click_via_search",
        "eventCategory": "Buy_used_cars_B2C_Help_Center",
        "eventAction": "question_click_via_search"
    },
    "HnSPageRequestCallClick": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "Request_Call_Click"
    },
    "HnSPageFaqClick": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "FAQ_Click"
    },
    "HnSPageWhatsappClick": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "WhatApp_Click"
    },
    "HnSPageMailClick": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "Mail_Click"
    },
    "HnSPageBackButtonClick": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "Backbutton_Click"
    },
    "HnSPageRmShown": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "RM_Shown"
    },
    "HnSPageRmCallClick": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "RM_Call_click"
    },
    "HnSPageRaShown": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "RA_Shown"
    },
    "HnSPageRaCallClick": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "RA_Call_click"
    },
    "HnSPageVisited": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "HnS_Page_Visited"
    },
    "HnSWhatsappHeaderClick": {
        "event": "custom_event",
        "eventCategory": "HnS_Page_v1",
        "eventAction": "WhatsApp_Header"
    }
};
