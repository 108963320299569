/* eslint-disable no-magic-numbers */
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Icons
import WhatsappIcon from "./images/icon-whatsapp.svg";

// Styles
import styles from "./styles.css";

// Utilities
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";

// Constants
import { HELPLINE_NUMBER, NUMBER } from "../../../constants/app-constants";
import { SELLER_HELPLINE_NUMBER } from "../../../constants/seller-app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { c2bSellerQuestionaireEvents } from "../../../tracking/msite/c2b-seller-questionaire-events";
import { b2cHelpPageEvents } from "../../../tracking/msite/b2c-help-page-events";
import { b2cCheckoutPageEvents } from "../../../tracking/msite/b2c-checkout-page-events";

const AdditionalHeight = NUMBER.SIXTY_EIGHT;

const NeedHelpChatIcon = ({
    message = "",
    hide = false,
    bottom = 20,
    right = 20,
    top,
    onToggleCallback = () => { },
    onClickAdditional = () => { },
    routeName,
    isBottomNavVisible = false,
    isAnotherCTAVisible = false,
    isShowCheckLoanBudgetCta = false,
    isSeller,
    make,
    isDesktop = false
}) => {
    const chatRef = useRef();
    const history = useHistory();

    const calculateBottom = () => {
        let updatedBottom = bottom;
        if (isAnotherCTAVisible) updatedBottom += AdditionalHeight;
        if (isBottomNavVisible) updatedBottom += AdditionalHeight;
        if (isShowCheckLoanBudgetCta) updatedBottom += AdditionalHeight;
        return updatedBottom;
    };

    const openWhatsappChat = async () => {
        trackPlatformCustomEventsAEV2(c2bSellerQuestionaireEvents.getSellWhatsappBookmark);
        const defaultMsg = `Hi,\r\n I’m interested in selling my ${make?.name || ""} car to CARS24`;
        const whatsappUrl = `https://wa.me/${SELLER_HELPLINE_NUMBER.whatsapp}?text=${defaultMsg}`;
        await yieldToMainThread();
        window.open(whatsappUrl, "_self");
    };

    const openSeamlessCheckoutWA = async () => {
        const whatsappUrl = `https://wa.me/${HELPLINE_NUMBER.whatsapp}?text=${message}`;
        try {
            await yieldToMainThread();
            window.open(whatsappUrl, "_self");
        } catch (e) {
            //optional catch binding
        }
        trackPlatformCustomEventsAEV2(b2cHelpPageEvents.b2cChatOnWhatsapp);
    };

    const redirectToHelpCenter = async (e) => {
        e.stopPropagation();
        onClickAdditional();
        if (isSeller) {
            openWhatsappChat();
            return;
        } else if ((routeName === ROUTE_NAME.CAR_DETAILS_CHECKOUT) || isDesktop) {
            trackPlatformCustomEventsAEV2(b2cCheckoutPageEvents.whatsappNewCheckout);
            openSeamlessCheckoutWA();
            return;
        }
        onToggleCallback();
        await yieldToMainThread();
        history.push({ pathname: `/help-center`, search: `?source=${routeName}`, state: { message } });
    };

    return (
        <div
            ref={chatRef}
            style={{ right, bottom: calculateBottom(), top, ...(hide ? { visibility: "hidden" } : {}) }}
            styleName={"styles.chatbot styles.mobileDefaultArrow"} className="chatbot">
            <div role="button" onClick={redirectToHelpCenter}>
                <img src={WhatsappIcon} alt="whatsapp" width={40} height={40} />
            </div>
        </div>
    );
};

NeedHelpChatIcon.propTypes = {
    message: PropTypes.string,
    hide: PropTypes.bool,
    bottom: PropTypes.number,
    right: PropTypes.number,
    onToggleCallback: PropTypes.func,
    onClickAdditional: PropTypes.func,
    routeName: PropTypes.string,
    eventLabel: PropTypes.string,
    isSeller: PropTypes.bool,
    top: PropTypes.number,
    make: PropTypes.object,
    isBottomNavVisible: PropTypes.bool,
    isAnotherCTAVisible: PropTypes.bool,
    experiments: PropTypes.object,
    isDesktop: PropTypes.bool,
    isShowCheckLoanBudgetCta: PropTypes.bool
};

export default NeedHelpChatIcon;
