export const b2cCheckoutPageEvents =  {
    "b2cDeliveryPagePickupCardClickCheckout": {
        "event": "b2c_delivery_page_pickup_card_click",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "delivery_page_pickup_card_click"
    },
    "deliveryPageHomeDeliveryCardClickCheckout": {
        "event": "b2c_delivery_page_pickup_card_click",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "delivery_page_home_delivery_card_click"
    },
    "checkoutLand": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "checkout_land"
    },
    "bctypeSelected": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "bctype_selected"
    },
    "b2cPersonalDetailPageOpen": {
        "event": "b2c_personal_detail_page_open",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "personal_detail_page_open",
        "eventLabel": "NA"
    },
    "b2cConfirmBookingPageCheckout": {
        "event": "b2c_confirm_booking_page_checkout",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "confirm_booking_page_checkout",
        "eventLabel": "NA"
    },
    "b2c_confirm_booking_page_confirmation": {
        "event": "b2c_confirm_booking_page_confirmation",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "confirm_booking_page_confirmation"
    },
    "b2c_pushedBookingInitiated": {
        "event": "b2c_pushed_Booking_initiated",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "pushed_booking_initiated"
    },
    "b2cPushedPaymentInitiated": {
        "event": "b2c_pushed_payment_initiated",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "pushed_payment_initiated"
    },
    "b2cPurchaseProcessProceedCTA": {
        "event": "b2c_purchase_process_proceed_CTA",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "purchase_process_proceed_CTA",
        "eventLabel": "NA"
    },
    "b2cCallPaymentMode": {
        "event": "b2c_call_payment_mode",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "call_payment_mode",
        "eventLabel": "NA"
    },
    "b2cCallDeliveryMode": {
        "event": "b2c_call_delivery_mode",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "call_delivery_mode",
        "eventLabel": "NA"
    },
    "b2cCallBookingType": {
        "event": "b2c_call_booking_type",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "call_booking_type",
        "eventLabel": "NA"
    },
    "b2cCallConfirmReservation": {
        "event": "b2c_call_confirm_reservation",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "call_confirm_reservation",
        "eventLabel": "NA"
    },
    "b2cBackCheckout": {
        "event": "b2c_back_checkout",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "back_checkout"
    },
    "retryPaymentCheckout": {
        "event": "retry_payment_checkout",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "retry_payment",
        "eventLabel": "NA"
    },
    "paymentFailed": {
        "event": "payment_failed",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "payment_failed",
        "eventLabel": "NA"
    },
    "tradeInNo": {
        "eventAction": "trade_in",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "event": "trade_in",
        "eventLabel": "trade_in_no"
    },
    "tradeInYes": {
        "eventAction": "trade_in",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "event": "trade_in",
        "eventLabel": "trade_in_yes"
    },
    "mrlDeliveryMode": {
        "eventAction": "pushed_delivery_mode",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "event": "b2c_pushed_delivery_mode"
    },
    "bookNowTd": {
        "eventAction": "book_now",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "event": "b2c_book_now"
    },
    "tdTypeHub": {
        "eventAction": "td_type_proceed",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "event": "td_type_proceed",
        "eventLabel": "td_type_hub"
    },
    "tdTypeHome": {
        "eventAction": "td_type_proceed",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "event": "td_type_proceed",
        "eventLabel": "td_type_home"
    },
    "couponBoxClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "coupon_box_click",
        "eventLabel": "NA"
    },
    "couponClickCheckout": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "coupon_click_checkout"
    },
    "couponSearchClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "coupon_search_click",
        "eventLabel": "NA"
    },
    "couponSearchApply": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "coupon_search_apply"
    },
    "couponApply": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "coupon_apply"
    },
    "couponSuccess": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "coupon_success"
    },
    "couponFailure": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "coupon_failure"
    },
    "couponRemove": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "coupon_remove"
    },
    "whatsappNewCheckout": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Whatsapp_new_checkout",
        "eventLabel": "NA"
    },
    "mrlExplored": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "MRL_explored"
    },
    "homeTab": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Home_tab"
    },
    "slotSelectionPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Slot_Selection_Page"
    },
    "switchToHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Switch_to_HOME"
    },
    "paymentFailureModal": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Payment_Failure_Modal"
    },
    "backButtonNoHub": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Back_Button_no_Hub",
        "eventLabel": "NA"
    },
    "backButtonExplored": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Back_Button_explored",
        "eventLabel": "NA"
    },
    "couponCodeCheckout": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Coupon_code"
    },
    "carImageClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Car_image_click",
        "eventLabel": "NA"
    },
    "helpNew": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "Help_New"
    },
    "paymentPopup": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "payment_popup"
    },
    "locationSelected": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "location_selected"
    },
    "oldLocationSelected": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "old_location_selected"
    },
    "currentLocationTDS": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "current_location"
    },
    "dateSelectedHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "date_selected_home"
    },
    "dateMoreDateHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "date_more_date_home"
    },
    "slotSelectedHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "slot_selected_home"
    },
    "addressEnter": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "address_enter"
    },
    "dateSelectedHub": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "date_selected_hub"
    },
    "dateMoreDateHub": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "date_more_date_hub"
    },
    "slotSelectedHub": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "slot_selected_hub"
    },
    "helpCtaCheckout": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "help_cta_checkout"
    },
    "agentConnect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "agent_connect"
    },
    "hubLocationPopup1": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "hublocation_popup1"
    },
    "hubLocationPopup2": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "hublocation_popup2"
    },
    "leavingModalBackButton": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "backbutton_popup"
    },
    "leavingModalStillLookingOption": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "still_looking"
    },
    "leavingModalExploreLoanOption": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "explore_loan"
    },
    "loanEligibilityWalkInHubOption": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "walk_in_hub"
    },
    "loanEligibilityExitCta": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "exit_car_detail"
    },
    "b2c_biPrimePreBi": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Checkout_Page",
        "eventAction": "b2c_biPrimePreBi"
    }
};
