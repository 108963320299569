import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NeedHelpChatIcon from "./component";

const mapStateToProps = ({
    config: { experiments }
}) => ({
    experiments
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NeedHelpChatIcon);
