import React from "react";
import PropTypes from "prop-types";

// Component
import NeedHelpChatIcon from "../../shared/need-help-chat-icon";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";

// Constants
import { ROUTE_NAME } from "../../../constants/routes-constants";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";
//import { INVENTORY_TYPE_LABEL } from "../../../constants/app-constants";

// based on route names
const WHATSAPP_INFO = {
    [ROUTE_NAME.HOME]: { type: "t1", eventLabel: "Home_page" },
    [ROUTE_NAME.LISTING]: { type: "t1", eventLabel: "Listing_page" },
    [ROUTE_NAME.DETAIL_PAGE]: { type: "t2", eventLabel: "Details_page" },
    [ROUTE_NAME.CAR_DETAILS_CHECKOUT]: { type: "t3", eventLabel: "checkout" },
    [ROUTE_NAME.CAR_EVALUATION]: { type: "", eventLabel: "Car_evaluation" },
    [ROUTE_NAME.SELL_ONLINE]: { type: "", eventLabel: "Sell_online" },
    [ROUTE_NAME.EPIC_DROP_SALE]: { type: "", eventLabel: "Epic_drop_Sale" },
    [ROUTE_NAME.AI_RECOMMENDED_CARS]: { type: "", eventLabel: "ai_car_finder" },
    [ROUTE_NAME.CAR_LOAN]: { type: "", eventLabel: "Finance_page" }
};

const checkoutStepMap = {
    "process": "process_page",
    "financing": "process_page_payment_mode",
    "delivery": "process_page_test_drive",
    "bookingType": "process_page_booking_type",
    "confirm": "process_page_reservation"
};

const getLabel = (routeName, checkoutStep = "") => {
    let label = WHATSAPP_INFO?.[routeName]?.eventLabel;
    if (label === "checkout") {
        label = checkoutStepMap[checkoutStep] || "N/A";
    }
    return label;
};

const getMessageText = (routeName, data) => {
    let msg = `Hi, \r\n I needed to get in touch with CARS24 agent.`;
    const type = WHATSAPP_INFO[routeName]?.type;
    if (type === "t2" || type === "t3") {
        const { shareUrl: link } = data;
        const { make, model, year } = data || { year: "", make: "", model: "" };
        msg = data.make ? `${type === "t3" ? "Hey team" : "Hi"}, \r\n I need help with ${make || ""} ${model || ""} ${year || ""} - ${link || ""}.` : msg;
    } else {
        msg = `Hi, \r\n I want to get in touch with a CARS24 agent.`;
    }
    return msg;
};

const ChatIcon = ({
    route,
    iconProperties,
    carDetails = {},
    checkoutStep,
    setChatIconPropertyConnect,
    make,
    isBottomNavVisible,
    onClickAdditional = () => {},
    isAnotherCTAVisible,
    isShowCheckLoanBudgetCta = false
}) => {
    const { content = {} } = carDetails || {};
    //const { inventoryType } = content || {};
    //const isCarC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;
    const eventLabel = getLabel(route.name, checkoutStep);

    const onToggleCallback = () => {
        onClickAdditional();
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.b2cWhatsappChat, { eventLabel: eventLabel || ""});
        setChatIconPropertyConnect(route.name, { variation: "collapsible" });
    };

    const getOtherProperties = () => {
        // if (isCarC2C && route.name === ROUTE_NAME.CAR_DETAILS_CHECKOUT) {
        //     return { hide: true };
        // }
        if ((route && route.name && iconProperties[route.name])) {
            return iconProperties[route.name];
        } else {
            return { hide: true };
        }
    };

    return (
        <NeedHelpChatIcon
            onToggleCallback={onToggleCallback}
            onClickAdditional={onClickAdditional}
            isBottomNavVisible={isBottomNavVisible}
            isAnotherCTAVisible={isAnotherCTAVisible}
            isShowCheckLoanBudgetCta={isShowCheckLoanBudgetCta}
            {...getOtherProperties()}
            routeName={route ? route.name : ""}
            eventLabel={eventLabel}
            message={getMessageText(route.name, content)}
            make={make}
        />
    );
};

ChatIcon.propTypes = {
    route: PropTypes.object,
    iconProperties: PropTypes.object,
    carDetails: PropTypes.object,
    setChatIconProperty: PropTypes.func,
    onClickAdditional: PropTypes.func,
    setChatIconPropertyConnect: PropTypes.func,
    checkoutStep: PropTypes.string,
    make: PropTypes.object,
    isBottomNavVisible: PropTypes.bool,
    isAnotherCTAVisible: PropTypes.bool,
    experiments: PropTypes.object,
    isShowCheckLoanBudgetCta: PropTypes.bool
};

export default ChatIcon;
